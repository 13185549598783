<ng-container [formGroup]="dayGroup">
  <nh-radio-group formControlName="_selectNthDayOrWeekday" class="day-group">
    <div class="nh-row">
      <nh-radio
        class="nh-col-s"
        value="nthDay"
        [label]="'_resource-planning._recurrence._periodicity-monthly._planning-on-date.descr-1' | i18next"
      />
      <ng-container formGroupName="nthDay">
        <nh-control class="nh-col-s">
          <nxh-number-field formControlName="nthDay" [min]="1" [max]="31" [roundingPrecision]="0" />
          <val-errors [label]="'day' | i18next" />
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-date.descr-2' | i18next }}
        </div>
        <nh-control class="nh-col-s">
          <nxh-number-field formControlName="_period" [min]="1" [roundingPrecision]="0" />
          <val-errors [label]="'period' | i18next" />
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-date.descr-3' | i18next }}
        </div>
      </ng-container>
    </div>

    <div class="nh-row">
      <nh-radio
        class="nh-col-s"
        value="weekday"
        [nxhDataTest]="'radio-weekday'"
        [label]="'_resource-planning._recurrence._periodicity-monthly._planning-on-day.descr-1' | i18next"
      />
      <ng-container formGroupName="weekday">
        <nh-control class="nh-col-2 nh-col--nopad">
          <ng-select formControlName="nth" [clearable]="false" [nxhDataTest]="'weekday-ordinal-number'">
            <ng-option [value]="1">{{ 'first' | i18next }}</ng-option>
            <ng-option [value]="2">{{ 'second' | i18next }}</ng-option>
            <ng-option [value]="3">{{ 'third' | i18next }}</ng-option>
            <ng-option [value]="4">{{ 'fourth' | i18next }}</ng-option>
            <ng-option [value]="-1">{{ 'last' | i18next }}</ng-option>
          </ng-select>
        </nh-control>
        <nh-control class="nh-col-3 nh-col--nopad">
          <ng-select formControlName="weekday" [clearable]="false" [nxhDataTest]="'weekday-day'">
            <ng-option *ngFor="let day of dayOptions" [value]="day">
              {{ day | i18next }}
            </ng-option>
          </ng-select>
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-day.descr-2' | i18next }}
        </div>
        <nh-control class="nh-col-s">
          <nxh-number-field
            formControlName="_period"
            [min]="1"
            [max]="31"
            [roundingPrecision]="0"
            [nxhDataTest]="'weekday-every'"
          />
          <val-errors [label]="'period' | i18next" />
        </nh-control>
        <div class="nh-col-s no-capitalization">
          {{ '_resource-planning._recurrence._periodicity-monthly._planning-on-day.descr-3' | i18next }}
        </div>
      </ng-container>
    </div>

    <div class="nh-row" *ngIf="useMonthSelectDays">
      <div class="selected-days-option">
        <nh-radio
          class="nh-col-s"
          value="selectedDays"
          [nxhDataTest]="'radio-select-days'"
          [label]="'_resource-planning._recurrence.on-the-following-days' | i18next"
        />
        <div formGroupName="selectedDays">
          <nxh-multi-toggle-list
            formControlName="days"
            [nxhDataTest]="'select-days'"
            [options]="daysOfMonth"
            [pills]="true"
            [showCheckMarks]="false"
          />
        </div>
      </div>
    </div>

    <nxh-time-periods
      *ngIf="useTimeslots"
      [parent]="dayGroup"
      [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
      [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
    />

    <nxh-dayparts
      [parent]="daypartsFormGroup"
      [partOptions]="dayparts"
      [useDayPartAllDay]="useDayPartAllDay"
      [validateDayPartOrTime]="validateDayPartOrTime"
      *ngIf="$any(parent.get('month')?.get('dayparts')) as daypartsFormGroup"
    />
  </nh-radio-group>
</ng-container>
