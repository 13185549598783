import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ClockTime } from '@nexuzhealth/shared-domain';
import {
  add,
  addDays,
  differenceInYears,
  Duration,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  startOfDay,
} from 'date-fns';

export type MapToDate = (date: string | Date | null) => Date | null;

export const defaultMapToDate: MapToDate = (date) => (date ? startOfDay(new Date(date)) : null);

export function endDateAfterStartDateValidator(
  startDateFieldName: string = 'startDate',
  endDateFieldName: string = 'endDate',
  allowSameDate: boolean = true,
  mapToDate: MapToDate = defaultMapToDate,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDateField = control.get(startDateFieldName);
    const endDateField = control.get(endDateFieldName);

    if (!startDateField || startDateField.invalid || !endDateField || endDateField.invalid) {
      return null;
    }

    const startDate = startDateField?.value;
    const endDate = endDateField?.value;

    const startDateAsDate = mapToDate(startDate);
    const endDateAsDate = mapToDate(endDate);

    return startDateAsDate &&
      endDateAsDate &&
      (allowSameDate ? isBefore(endDateAsDate, startDateAsDate) : !isAfter(endDateAsDate, startDateAsDate))
      ? { endDateIsBeforeStartDate: true }
      : null;
  };
}

export function maxEndDateValidator(
  startDateFieldName: string,
  endDateFieldName: string,
  maxDateDifference: Duration,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate = control.get(startDateFieldName).value;
    const endDate = control.get(endDateFieldName).value;
    if (!startDate || !endDate) {
      return null;
    }
    const maxEndDate = add(startDate, maxDateDifference);
    return isAfter(endDate, maxEndDate) ? { 'max-date': true } : null;
  };
}

export function minEndDateValidator(
  startDateFieldName: string,
  endDateFieldName: string,
  maxDateDifference: Duration,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate = control.get(startDateFieldName).value;
    const endDate = control.get(endDateFieldName).value;
    if (!startDate || !endDate) {
      return null;
    }
    const minEndDate = add(startDate, maxDateDifference);
    return isBefore(endDate, minEndDate) ? { 'min-date': true } : null;
  };
}

export function endClockTimeAfterStartClockTimeValidator(
  startTimeFieldName: string,
  endTimeFieldName: string,
  sameTimeAllowed = true,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime: ClockTime = control.get(startTimeFieldName).value;
    const endTime: ClockTime = control.get(endTimeFieldName).value;

    if (!startTime || !endTime) {
      return null;
    }

    const startNumber = startTime.hours * 60 * 60 + startTime.minutes * 60 + startTime.seconds;
    const endNumber = endTime.hours * 60 * 60 + endTime.minutes * 60 + endTime.seconds;

    if (!sameTimeAllowed && startNumber === endNumber) {
      return { endTimeIsSameAsStartTime: true };
    }
    return startNumber > endNumber ? { endTimeIsBeforeStartTime: true } : null;
  };
}

export function maxDateValidator(date: Date) {
  return (control: AbstractControl) => {
    return control.value && isAfter(endOfDay(control.value), endOfDay(date)) ? { 'max-date': true } : null;
  };
}

export function minDateValidator(date: Date) {
  return (control: AbstractControl) => {
    return control.value && isBefore(startOfDay(control.value), startOfDay(date)) ? { 'min-date': true } : null;
  };
}

/**
 * Validate date control value against date.now on change. Date has to be in the past to be valid.
 * Set inclusive to true to allow same dates
 */
export function dateInPastValidator(inclusive = false): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const now = startOfDay(new Date());
    let valid = isBefore(control.value, now);
    if (!valid && inclusive) {
      valid = isSameDay(control.value, now);
    }
    return valid ? null : { 'past-date': true };
  };
}

/**
 * Validate date control value against date.now on change. Date has to be in the future to be valid.
 * Set inclusive to true to allow same dates
 */
export function dateInFutureValidator(inclusive = false) {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const now = endOfDay(new Date());
    let valid = isAfter(control.value, now);
    if (!valid && inclusive) {
      valid = isSameDay(control.value, now);
    }
    return valid ? null : { 'future-date': true };
  };
}

export function invalidDateValidator() {
  return (control: AbstractControl) => {
    const date = control.value;
    return isInvalidDateMessage(date) || (date && !isValidDate(date)) ? { 'invalid-date': true } : null;
  };
}

export function isValidDate(date: Date) {
  return date && /\d/.test(date.toString());
}

const invalid_date_message = 'invalid date';

export function isInvalidDateMessage(value) {
  return typeof value === 'string' && invalid_date_message === value.toLowerCase();
}

export function dateTimeGroupInPast(dateFieldName: string = 'date', timeFieldName: string = 'time') {
  return (control: AbstractControl): ValidationErrors | null => {
    const date: Date = control.get(dateFieldName).value;
    const time: ClockTime = control.get(timeFieldName).value;

    if (!date || !time) {
      return null;
    }

    const dateWithTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.hours,
      time.minutes,
      time.seconds || 0,
    );
    return isAfter(dateWithTime, new Date()) ? { 'max-date': true } : null;
  };
}

export function maxDifferenceInYears(
  startTimeFieldName: string,
  endTimeFieldName: string,
  maxYears: number,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime: Date = control.get(startTimeFieldName).value;
    const endTime: Date = control.get(endTimeFieldName).value;

    if (!startTime || !endTime) {
      return null;
    }

    // add 1 day to startDate, because a difference of "exact" maxYears is also considered ok
    return differenceInYears(startOfDay(endTime), addDays(startOfDay(startTime), 1)) >= maxYears
      ? { maxDifference: { max: maxYears, unit: 'year' } }
      : null;
  };
}

// Works on DateTimeComponent where time also needs to be in the past.
export function dateTimeInPast() {
  return (control: AbstractControl) => {
    const date: Date = typeof control.value === 'string' ? new Date(control.value) : control.value;
    if (!date) {
      return null;
    }
    return isAfter(date, new Date()) ? { 'max-date': true } : null;
  };
}

export function dateTimeNotAfter(dateTime: Date) {
  return (control: AbstractControl) => {
    const value: Date = new Date(control.value);
    if (dateTime && value) {
      if (isEqual(value, dateTime) || isBefore(value, dateTime)) {
        return { 'dateTime-not-after': true };
      }
    }
    return null;
  };
}

// Validator to check that ClockTime is not 00:00 (needed for duration)
export const zeroClockTime: ValidatorFn = (control: AbstractControl) => {
  const time: ClockTime = control.value;
  if (!time) {
    return null;
  }

  return !(time.hours === 0 && time.minutes === 0) ? null : { 'zero-time': true };
};
