import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime, DateTimePrecision } from '@nexuzhealth/shared-domain';
import { isAfter, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { mapDateToDateTime } from '@nexuzhealth/shared-util';
import { PartialDate } from './partial-date.model';

export function mapDateObjectToPartialDate(dateTime: DateTime): PartialDate | null {
  if (!dateTime) {
    return null;
  }

  try {
    const date: Date = new Date(Date.parse(dateTime.value));

    switch (dateTime.precision) {
      case DateTimePrecision.DAY:
        return { year: `${date.getUTCFullYear()}`, month: `${date.getUTCMonth() + 1}`, day: `${date.getUTCDate()}` };
      case DateTimePrecision.MONTH:
        return { year: `${date.getUTCFullYear()}`, month: `${date.getUTCMonth() + 1}`, day: '' };
      case DateTimePrecision.YEAR:
        return { year: `${date.getUTCFullYear()}`, month: '', day: '' };
    }
  } catch (e) {
    console.error('Error while parsing DateObject', dateTime);
  }

  return { year: '', month: '', day: '' };
}

export function mapToDateObject(partialDate: PartialDate): DateTime | null {
  if (!partialDate?.year) {
    return null;
  }

  if (partialDate.day) {
    return mapDateToDateTime(
      new Date(+partialDate.year, +partialDate.month - 1, +partialDate.day),
      DateTimePrecision.DAY,
    );
  }

  if (partialDate.month) {
    return mapDateToDateTime(new Date(+partialDate.year, +partialDate.month - 1), DateTimePrecision.MONTH);
  }

  if (partialDate.year) {
    // when there's only 1, numeric param, this one is interpreted as 'miliseconds after 1970', so we have to pass it as a string
    return mapDateToDateTime(new Date(partialDate.year), DateTimePrecision.YEAR);
  }

  return null;
}

export function mapToDate(partialDate: PartialDate): Date | null {
  if (!partialDate || !partialDate.year) {
    return null;
  }

  if (partialDate.day) {
    return startOfDay(new Date(+partialDate.year, +partialDate.month - 1, +partialDate.day));
  }

  if (partialDate.month) {
    return startOfMonth(new Date(+partialDate.year, +partialDate.month - 1));
  }

  return startOfYear(new Date(partialDate.year));
}

export function mapToPartialDate(date: Date): PartialDate | void {
  if (!date) return;
  return { year: `${date.getFullYear()}`, month: `${date.getMonth() + 1}`, day: `${date.getDate()}` };
}

export const validateDateInFuture: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const date = control.value as PartialDate;

  return date && isFuture(date) ? { 'date-in-future': true } : null;
};

const isFuture = (partialDate: PartialDate) => {
  const now = new Date();
  if (partialDate.day) {
    const date = mapToDate(partialDate);
    return date && isAfter(date, startOfDay(now));
  } else if (partialDate.month) {
    const date = mapToDate(partialDate);
    return date && isAfter(date, startOfMonth(now));
  } else {
    const date = mapToDate(partialDate);
    return date && isAfter(date, startOfYear(now));
  }
};
