import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Metadata, ResourceName } from '@nexuzhealth/shared-domain';
import { printFile, RetryBackoffConfig } from '@nexuzhealth/shared-util';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { IBlobService } from '@nexuzhealth/shared-tech-domain';
import { BlobApiService } from '../api/blob-api.service';
import { PictureStore } from './picture.store';
import { PictureQuery } from './picture.query';
import { Picture } from './picture.model';

export type Blob = any;
export const defaultRetryBackoffConfig: RetryBackoffConfig = {
  initialBackoffMs: 100,
  maxBackoffMs: 60000,
  maxRetries: 20,
};

@Injectable({ providedIn: 'root' })
export class BlobService implements IBlobService {
  constructor(
    private blobApi: BlobApiService,
    private pictureQuery: PictureQuery,
    private pictureStore: PictureStore,
  ) {}

  getBlob(blobName: ResourceName | undefined, download = true): Observable<HttpResponse<Blob>> {
    if (!blobName) {
      return of(null);
    }

    return this.blobApi.getBlob(blobName, download);
  }

  getBlobWithFilenameAndRetry(
    blobName: ResourceName | undefined,
    filename: string,
    retryConfig = defaultRetryBackoffConfig,
    download = true,
  ): Observable<HttpResponse<Blob>> {
    if (!blobName) {
      return of(null);
    }

    return this.blobApi.getBlobWithRetry(blobName, retryConfig, download, filename);
  }

  getBlobWithRetry(
    blobName: ResourceName,
    retryConfig = defaultRetryBackoffConfig,
    download = true,
  ): Observable<HttpResponse<Blob>> {
    return this.blobApi.getBlobWithRetry(blobName, retryConfig, download);
  }

  getBlobMetaData(blobName: ResourceName): Observable<Metadata> {
    return this.blobApi.getBlobMetaData(blobName);
  }

  createBlob(file: File) {
    return this.blobApi.createBlob(file);
  }

  createBlobWithUploadProgress(blob: Blob) {
    return this.blobApi.createBlobWithUploadProgress(blob);
  }

  /**
   * Loads a picture as data-url
   * @param pictureName Name of the to-be-retrieved picture.
   * @param fromCache Awaiting a decision from the platform guild, we offer the possibility to cache pictureNames for
   * a limited time. Should we later on decide to cache images through HTTP's Cache-Control header, we will remove
   * this option. Defaults to false.
   */
  loadAsDataUrl(pictureName: ResourceName, fromCache = false) {
    const getPicture = this.getBlob(pictureName, false).pipe(
      map((response) => ({ name: pictureName, blob: response.body, loading: false })),
    );

    const picture$: Observable<Picture> = fromCache
      ? this.pictureQuery.selectEntity(pictureName).pipe(
          switchMap((picture) => {
            if (!picture) {
              this.pictureStore.add({ name: pictureName, loading: true });
              return getPicture.pipe(
                tap((loadedPicture: Picture) => {
                  this.pictureStore.upsert(pictureName, loadedPicture);
                  // keep in cache for 10 mins at the most
                  setTimeout(() => this.pictureStore.remove(pictureName), 10 * 60_000);
                }),
              );
            } else {
              return of(picture);
            }
          }),
          filter((picture) => picture && picture.loading === false),
          take(1),
        )
      : getPicture;

    return picture$.pipe(
      switchMap((picture: Picture) => {
        const picture$$ = new Subject<string>();
        if (!picture?.blob) {
          return of('');
        }
        const reader = new FileReader();
        reader.readAsDataURL(picture.blob);
        reader.onload = () => {
          picture$$.next(reader.result as string);
        };

        return picture$$;
      }),
      catchError(() => of('')),
    );
  }

  printBlob(blobName: ResourceName): Observable<Event> {
    return this.blobApi.getBlob(blobName, false).pipe(
      map((response) => response.body),
      switchMap((blob) => printFile(blob)),
    );
  }
}
