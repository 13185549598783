<ng-container [ngSwitch]="!!controlComponent">
  <div class="number-field has-control-component" *ngSwitchCase="true">
    <span class="prefix" *ngIf="prefix">{{ prefix }}</span>
    <input
      #inputCC
      class="form-control"
      [class.nxh-align-left]="alignment === 'left'"
      [class.nxh-align-right]="alignment === 'right'"
      [class.has-prefix]="prefix"
      [class.has-suffix]="suffix"
      (input)="onInput($event)"
      (blur)="onBlur($event)"
      type="number"
      name="number-field"
      [step]="step"
      [disabled]="disabled"
      [placeholder]="placeholder || ''"
      [tabIndex]="tabIndex"
      [min]="min"
      [max]="max"
      [(ngModel)]="value"
    />
    <span class="suffix" *ngIf="suffix">{{ suffix }}</span>
  </div>
  <div class="number-field" [class.no-label]="!label" *ngSwitchDefault>
    <span class="prefix" *ngIf="prefix">{{ prefix }}</span>
    <input
      #input
      class="form-control"
      [class.nxh-align-left]="alignment === 'left'"
      [class.nxh-align-right]="alignment === 'right'"
      [class.has-prefix]="prefix"
      [class.has-suffix]="suffix"
      (input)="onInput($event)"
      (blur)="onBlur($event)"
      nxhCompactFormControl=""
      [nxhControlError]="errorMessage"
      type="number"
      name="number-field"
      [step]="step"
      [disabled]="disabled"
      [label]="label"
      [placeholder]="placeholder || ''"
      [tabIndex]="tabIndex"
      [required]="required"
      [errorMap]="errorMap"
      [min]="min"
      [max]="max"
      [(ngModel)]="value"
    />
    <span class="suffix" *ngIf="suffix">{{ suffix }}</span>
  </div>
</ng-container>
