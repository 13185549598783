import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';

type LocationState = { navigationId: number };

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  constructor(
    private location: Location,
    private router: Router,
  ) {}

  /**
   * Redirects to the previous page, or if the location history is empty, to
   * the fallback url (default '/')
   */
  back(fallback: string | any[] | null = null, extras: NavigationExtras = {}) {
    const state = this.location.getState() as LocationState;
    if (state.navigationId > 1) {
      // if not first page in history, go back:
      this.location.back();
    } else if (fallback) {
      // if first page, go to specified fallback url:
      const route = Array.isArray(fallback) ? fallback : [fallback];
      this.router.navigate(route, extras);
    } else {
      // when all else fails, go home:
      this.router.navigate(['/']);
    }
  }

  /**
   * Returns false in case there exists a page in the location history (incl current one), otherwise
   * returns a UrlTree of the given fallback url (default '/'). Typically used by RouteGuards
   */
  dismiss(fallback = '/'): boolean | UrlTree {
    if (this.router.navigated) {
      return false;
    } else {
      return this.router.parseUrl(fallback);
    }
  }
}
