export type ToolkitTypeValue = 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'disabled' | 'info';

export enum ToolkitType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Disabled = 'disabled',
  Info = 'info',
}

export type PillCategoryValue =
  | 'darkblue'
  | 'darkgreen'
  | 'darkergreen'
  | 'grey'
  | 'lightblue'
  | 'lightgreen'
  | 'lightgrey'
  | 'lightorange'
  | 'lightred'
  | 'purple'
  | 'red'
  | 'yellow'
  | 'mynxhbrand';

export enum PillCategory {
  DarkBlue = 'darkblue',
  DarkGreen = 'darkgreen',
  DarkerGreen = 'darkergreen',
  Grey = 'grey',
  LightBlue = 'lightblue',
  LightGreen = 'lightgreen',
  LightGrey = 'lightgrey',
  LightOrange = 'lightorange',
  LightRed = 'lightred',
  Purple = 'purple',
  Red = 'red',
  Yellow = 'yellow',
  MynxhBrand = 'mynxhbrand',
}
