import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { Focusable, FOCUSSABLE } from '@nexuzhealth/shared-ui-toolkit/focus';

@Component({
  selector: 'nxh-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
    {
      provide: FOCUSSABLE,
      useExisting: forwardRef(() => PasswordInputComponent),
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'no_form-control-class',
  },
})
export class PasswordInputComponent implements Focusable, ControlValueAccessor {
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  @Input() placeholder = '';

  @ViewChild('input', { static: true }) field!: ElementRef<HTMLInputElement>;

  onChange!: (password: string) => void;
  onTouch!: () => void;
  isHidden = true;

  constructor(private renderer: Renderer2) {}

  setFocus() {
    this.field.nativeElement.focus();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.field.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: string) {
    this.renderer.setProperty(this.field.nativeElement, 'value', value);
  }

  valueChanged($event: Event) {
    if (this.onChange) {
      const el = $event.target as HTMLInputElement;
      this.onChange(el.value);
    }
  }
}
