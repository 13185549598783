import { Address } from './address';
import { ContactPoint, PersonName } from './common';
import { BasOrganisation } from './organisation.model';
import { AssignedId } from './assigned-id';

export interface Practitioner {
  name: string;
  practice: string;
  isGmd: boolean;
  contexts: PractitionerContext[];
  professions: PractitionerProfession[];
  professionCode: string;
  birthDate: string;
  deathDate?: string;
  qualifications: PractitionerQualification[];
  sendVersion?: string;
  inssRequired: boolean;
  personName: PersonName;
  administrativeGender: string;
  assignedIds: AssignedId[];
  addresses?: Record<string, Address>;
  contactPoints?: Record<string, ContactPoints>;
}

export interface ContactPoints {
  phone: string;
  mobile: string;
  email: string;
}

export interface PractitionerQualification {
  name: string;
  sendVersion: string;
  healthCareWorkerQualification?: HealthCareWorkerQualification;
  startDate: Date;
  endDate?: Date;
  qualificationCode?: string;
}

export interface HealthCareWorkerQualification {
  name: string;
  sendVersion: string;
  healthCareWorkerProfession?: HealthCareProfession;
  startDate?: string;
  endDate?: string;
  qualificationCode?: string;
  healthCareWorkerQualificationDescriptions?: HealthCareWorkerQualificationDescription[];
}

export interface HealthCareWorkerQualificationDescription {
  name: string;
  sendVersion: string;
  languageName: string;
  description: string;
}

export interface PractitionerContext {
  addresses: Address[];
  contactPoints: ContactPoint[];
  name: string;
  organisationName: string;
  organisation?: BasOrganisation;
  sendVersion: string;
  startTime: string;
  endTime: string;
  practitioner?: Practitioner;
  professions?: PractitionerProfession[];
  professionCode?: string;
}

export interface PractitionerProfessionalData {
  name: string;
  sendVersion?: string;
  nihii: string;
  profession: PractitionerProfession;
  qualifications: PractitionerQualification[];
}

export interface PractitionerProfession {
  name?: string;
  practitionerIdentifierName?: string;
  sendVersion?: string;
  startTime?: string;
  endTime?: string;
  professionCode: string;
}

export interface GP {
  practitionerName: string;
  patientName: string;
  sendVersion?: string;
  details?: {
    personName: PersonName;
  };
}

export function practitionerFromPractitionerContext(contextName: string): string {
  if (!contextName) {
    return '';
  }
  return contextName.split('/').splice(0, 2).join('/');
}

export interface HealthCareProfession {
  name: string;
  description: string;
  translationKey: string;
  healthCareProfessionCodes: HealthCareProfessionCode[];
  healthCareWorkerQualifications?: HealthCareWorkerQualification[];
  sendVersion: string;
}

export interface HealthCareProfessionCode {
  name: string;
  source: Source;
  value: string;
  sendVersion: string;
}

export enum Source {
  Addressbook = 'ADDRESSBOOK',
  Ehbox = 'EHBOX',
  Kmehr = 'KMEHR',
  Riziv = 'RIZIV',
}

export enum ListType {
  Addressbook = 'LIST_TYPE_ADDRESSBOOK',
  Practitioner = 'LIST_TYPE_PRACTITIONER',
}
