import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Metadata } from '@nexuzhealth/shared-domain';
import { getHttpParams, retryBackoff, RetryBackoffConfig, timeoutHeaders } from '@nexuzhealth/shared-util';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
  addCoercedContentTypeToHeaders,
  getCoercedContentTypeIfNeeded,
} from '../shared/FileWithCoercedContentType.model';
import { SignedUrlResponse } from '../model/blob.model';
import { BlobResponseHandler } from './blob-response.handler';

@Injectable({
  providedIn: 'root',
})
export class BlobApiService {
  private readonly baseUrl = 'api/tech/blob/v1';

  constructor(
    protected http: HttpClient,
    protected blobResponseHandler: BlobResponseHandler,
  ) {}

  getBlobMetaData(blobName: string): Observable<Metadata> {
    return this.http.get<Metadata>(`${this.baseUrl}/${blobName}`, { params: { metadata: true } });
  }

  getBlob(blobName: string, download = true, filename?: string): Observable<HttpResponse<Blob>> {
    const url = `${this.baseUrl}/${blobName}`;
    const params = getHttpParams({ filename });
    return this.http
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: timeoutHeaders({ errorMillis: -1 }),
        params,
      })
      .pipe(
        tap((dt) => {
          if (download) this.blobResponseHandler.downloadFile(dt, dt.headers.get('content-type'));
        }),
      );
  }

  getBlobWithRetry(
    blobName: string,
    retryConfig: RetryBackoffConfig,
    download = true,
    filename?: string,
  ): Observable<HttpResponse<Blob>> {
    const params = getHttpParams({ filename });
    return this.http
      .get<Blob>(`${this.baseUrl}/${blobName}`, {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: timeoutHeaders({ errorMillis: -1 }),
        params,
      })
      .pipe(
        retryBackoff(retryConfig),
        tap((data) => {
          if (download) this.blobResponseHandler.downloadFile(data, data.headers.get('content-type'));
        }),
      );
  }

  createSignedUrl(file: File) {
    const url = `${this.baseUrl}/signedUrl`;

    // workaround for mimetype pkcs12
    const fileType = getCoercedContentTypeIfNeeded(file) ?? file.type;

    return this.http.post<SignedUrlResponse>(url, {
      contentType: fileType,
      fileName: file.name,
    });
  }

  createBlob(file: File) {
    return this.createSignedUrl(file).pipe(
      mergeMap(
        (response) => {
          return this.http.put(response.url, file, {
            headers: addCoercedContentTypeToHeaders(file, new HttpHeaders(response.headers)),
          });
        },
        (response) => response.blobName,
      ),
    );
  }

  createBlobWithUploadProgress(file: File) {
    return this.createSignedUrl(file).pipe(
      mergeMap(
        (response) => {
          return this.http.put(response.url, file, {
            headers: addCoercedContentTypeToHeaders(file, new HttpHeaders(response.headers)),
            reportProgress: true,
            observe: 'events',
          });
        },
        (response, event) => ({
          blobName: response.blobName,
          event: event,
        }),
      ),
    );
  }
}
