import {
  booleanAttribute,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ButtonDirective, ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AsyncPipe, NgIf } from '@angular/common';
import { I18NextModule } from 'angular-i18next';
import { FormHelperDirective } from '../form-helper.directive';
import { ConnectFormHelperDirective } from '../connect-form-helper.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-submit-button',
  templateUrl: './submit-form-button.component.html',
  styleUrls: ['./submit-form-button.component.scss'],
  imports: [ButtonModule, SharedTechFeatureE2eModule, FaIconComponent, NgIf, AsyncPipe, I18NextModule],
  standalone: true,
})
export class SubmitFormButtonComponent implements OnInit {
  @Input() label = 'save';
  @Input() icon: IconProp = 'check';
  @Input() type: ButtonDirective['buttonStatus'] = 'success';
  @Input() disabled: boolean | null = false;
  @Input() outline = false;
  @HostBinding('class.reserve-space-for-label') @Input({ transform: booleanAttribute }) reserveSpaceForLabel = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter();

  submitting$!: Observable<boolean>;
  hasCustomSubmit = false;

  private _formHelperDirective? = inject(FormHelperDirective, { optional: true });
  private connectFormHelperDirective? = inject(ConnectFormHelperDirective, { optional: true });

  @Input() set connect(formHelperDirective: FormHelperDirective) {
    this._formHelperDirective = formHelperDirective;
  }

  get formHelperDirective(): FormHelperDirective {
    // Note: order is important here! In the case where both connectFormHelperDirective and _formHelperDirective
    // have a value, we need to pick the one that is closest to this SubmitButton. In general, this is the
    // ConnectFormHelperDirective that is set on the parent ModalFooterComponent.
    const formHelperDirective = this.connectFormHelperDirective?.formHelperDirective || this._formHelperDirective;

    if (!formHelperDirective) {
      throw new Error(
        'nhForm directive is missing. Either embed the nh-submit-button in a form with an nhForm directive, or, ' +
          'for example when the submit-form-button is no descendent of a form, embed it in a nxh-modal-footer with ' +
          'a [connectNxhForm] directive.',
      );
    }

    return formHelperDirective;
  }

  get formId() {
    return this.formHelperDirective.formName;
  }

  get helper() {
    return this.formHelperDirective.helper;
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    if (this.disabled) {
      return;
    }

    this.helper.sourceSubmitButton = this;
    if (this.hasCustomSubmit) {
      $event.preventDefault();
      this.helper.formHelperDirective.customSubmitButtonClicked();
      this.submit.emit();
    }
  }

  ngOnInit(): void {
    this.hasCustomSubmit = this.submit.observers?.length > 0;
    this.submitting$ = this.helper.submitting$.pipe(
      filter(() => !this.hasCustomSubmit || this.helper.sourceSubmitButton === this),
    );
  }
}
