<div *ngIf="navItems" [class.hidden]="(hide$ | async) === true" class="tabnav">
  <ul class="tabnav_items-container">
    <ng-container *ngFor="let item of navItems; let i = index">
      <li *featureFlag="item.flagName">
        <a
          #rla="routerLinkActive"
          [class.enable-workaround]="activeItem !== null"
          [class.workaround-active]="activeItem === i"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
          [routerLink]="item.routerLink"
          routerLinkActive="active"
          [ngClass]="item.customClass"
          [skipLocationChange]="skipLocationChange"
          [queryParams]="item.queryParams"
          [queryParamsHandling]="item.queryParamsHandling ?? null"
        >
          {{ item.label | i18next }}
        </a>
      </li>
    </ng-container>
  </ul>
</div>

<div *ngIf="!navItems" [class.hidden]="(hide$ | async) === true" class="tabnav">
  <ul class="tabnav_items-container">
    <ng-content />
  </ul>
</div>
