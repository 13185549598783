<div class="nxh-input__prefix-container" *ngIf="hasPrefix$ | async">
  <ng-content select="[nxhPrefix]" />
</div>
<input
  #nativeInput
  [type]="type"
  class="form-control"
  [placeholder]="placeholder"
  [tabIndex]="tabIndex"
  (input)="valueChange($event)"
  (blur)="onTouch($event)"
  [value]="value"
/>
<div class="nxh-input__suffix-container" *ngIf="hasSuffix$ | async">
  <ng-content select="[nxhSuffix]" />
</div>
