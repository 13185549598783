import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BlobResponseHandler {
  downloadFile(data: HttpResponse<Blob>, type: string) {
    const contentDisposition = data.headers.get('content-disposition');
    let name = '';
    if (contentDisposition !== null) {
      name = contentDisposition.split('=').length >= 2 ? contentDisposition.split('=')[1] : '';
      name = this.trimByChar(name, '"');
    }
    const url = window.URL.createObjectURL(data.body);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data.url);
      link.remove();
    }, 100);
  }

  private trimByChar(fileName, character) {
    const first = [...fileName].findIndex((char) => char !== character);
    const last = [...fileName].reverse().findIndex((char) => char !== character);
    return fileName.substring(first, fileName.length - last);
  }
}
