import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { DAY } from '@nexuzhealth/shared-domain';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs/operators';
import { RecurrencesFormService } from '../recurrences-form.service';

@Component({
  selector: 'nxh-recurrence-tab-month',
  templateUrl: './recurrence-tab-month.component.html',
  styleUrls: ['./recurrence-tab-month.component.scss'],
})
export class RecurrenceTabMonthComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #recurrencesFormService = inject(RecurrencesFormService);

  @Input() parent!: UntypedFormGroup;

  get validateDayPartOrTime() {
    return this.#recurrencesFormService.getValidateDayPartOrTime();
  }

  get dayparts() {
    return this.#recurrencesFormService.getDayParts();
  }

  get days(): UntypedFormArray {
    return this.parent.get('month')?.get('days') as UntypedFormArray;
  }

  get dayGroup() {
    return this.days.controls[0] as UntypedFormGroup;
  }

  get useTimeslots() {
    return this.#recurrencesFormService.getUseTimeslots();
  }

  get useDayPartAllDay() {
    return this.#recurrencesFormService.getUseDayPartAllDay();
  }

  get useMonthSelectDays() {
    return this.#recurrencesFormService.getUseMonthSelectDays();
  }

  dayOptions = Object.values(DAY);
  daysOfMonth = Array(31)
    .fill(null)
    .map((day, index) => ({ value: String(index + 1), label: (index + 1 + '').padStart(2, '0') }));

  ngOnInit(): void {
    this.dayGroup
      .get('_selectNthDayOrWeekday')
      ?.valueChanges.pipe(
        startWith(this.dayGroup.get('_selectNthDayOrWeekday')?.value),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((value) => {
        if (value === 'nthDay') {
          this.dayGroup.get('nthDay')?.enable({ emitEvent: false });
          this.dayGroup.get('weekday')?.disable({ emitEvent: false });
          this.dayGroup.get('selectedDays')?.disable({ emitEvent: false });
        } else if (value === 'weekday') {
          this.dayGroup.get('nthDay')?.disable({ emitEvent: false });
          this.dayGroup.get('weekday')?.enable({ emitEvent: false });
          this.dayGroup.get('selectedDays')?.disable({ emitEvent: false });
        } else {
          this.dayGroup.get('nthDay')?.disable({ emitEvent: false });
          this.dayGroup.get('weekday')?.disable({ emitEvent: false });
          this.dayGroup.get('selectedDays')?.enable({ emitEvent: false });
        }
      });
  }
}
